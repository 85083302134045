const environments = {
    "development" : {
        "ga_id": "UA-000000-01",
        "api_url": "http://localhost:3090",
        "domain": "localhost",
    },
    "production" : {
        "ga_id": "UA-124434766-1",
        "api_url": "https://apirary.com/apibackend",
        "domain": "apirary.com",
    }
};

const env = environments[process.env.NODE_ENV || 'development'];

export default env;
