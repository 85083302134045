import env from "../config";
import axios from 'axios';
import humps from 'humps'
import { cacheAdapterEnhancer } from 'axios-extensions';
import applyCaseMiddleware from "axios-case-converter";
import {getAccessToken} from "./AuthService";

class HttpCacheAdapter {
    constructor(props, baseUrl= null) {
        this.props = props;
        this.baseUrl = baseUrl ?? env['api_url'];

        this.http = applyCaseMiddleware(axios.create({
            baseURL: this.baseUrl,
            headers: {'Cache-Control': 'no-cache'},
            adapter: cacheAdapterEnhancer(axios.defaults.adapter, {enabledByDefault: false}),
            transformResponse: [
                ...axios.defaults.transformResponse,
                data => humps.camelizeKeys(data)
            ],
            transformRequest: [
                data => humps.decamelizeKeys(data),
                ...axios.defaults.transformRequest
            ]
        }));

        this.config = {
            headers: {
                authorization: getAccessToken(),
            }
        };
    }

    authGet(uri, params=null) {
        if (params) {
            this.config.params = params;
        }

        return this.http.get(this.baseUrl + uri, this.config).catch(error => {
            if (!error.response) {
                return Promise.reject(error);
            }
            if (error.response.status === 401) {
            } else {
                return Promise.reject(error);
            }
        });
    }

    authRequest(uri, data, method="POST") {
        /** @type {Promise} request */
        if (this.config.params) {
            this.config.params = null;
        }

        let request;
        if (method === "POST") {
            request = this.http.post(this.baseUrl + uri, data, this.config);
        } else if (method === "PUT") {
            request = this.http.put(this.baseUrl + uri, data, this.config);
        } else if (method === "PATCH") {
            request = this.http.patch(this.baseUrl + uri, data, this.config);
        } else if (method === "DELETE") {
            request = this.http.delete(this.baseUrl + uri, this.config);
        }
        return request.catch(error => {
            if (error.response.status === 401) {
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        });
    }

    authDelete(uri) {
        return this.http.delete(this.baseUrl + uri, this.config).catch(error => {
            if (error.response.status === 401) {
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        });
    }
}

export default HttpCacheAdapter;
