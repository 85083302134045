import jwtDecode from "jwt-decode";
import {Cookies} from "react-cookie";
import env from '../config';
import RequestAuth from "./RequestAuth";

const COOKIE_KEY = "APIARY-WEB";

export const isUserAuthenticated = () => {
    const token = getAccessToken();
    if (!token || token === "") {
        return false;
    }
    const decoded = jwtDecode(token);
    return Date.parse(decoded.expired_at) > Date.now();
};

export const getAccessToken = () => {
    const cookies = new Cookies();
    return cookies.get(COOKIE_KEY);
};

export const currentUser = () => {
    if (!isUserAuthenticated()) {
        return null;
    }

    const token = jwtDecode(getAccessToken());
    return { userId: token.user_id, expiredAt: token.expired_at };
}

export const deleteAccessToken = () => {
    const cookies = new Cookies();
    return cookies.remove(COOKIE_KEY, {domain: env["domain"], path: "/"});
};

export const setAccessToken = (token) => {
    const cookies = new Cookies();
    cookies.set(COOKIE_KEY, token, {domain: env["domain"], path: "/", maxAge: 864000 * 14});
};

export const refreshToke = (token) => {
    const request = new RequestAuth(undefined);

    request.refreshToken(token).then(result => {
        deleteAccessToken();
        setAccessToken(result.data.token);
    }).catch(() => alert("토큰 갱신 오류"));
};
