import React from 'react';
import PublicRoute from "./PublicRoute";
//import PrivateRoute from "./PrivateRoute";

const Home = React.lazy(() => import('../pages/Home'));
const Videos = React.lazy(() => import('../pages/Videos'));
const Register = React.lazy(() => import('../pages/Register'));
const Inquiries = React.lazy(() => import('../pages/Inquiries'));

// root routes
const rootRoute = {
    name: 'APIARY',
    path: '/',
    exact: true,
    component: Home,
    route: PublicRoute,
};

const authRoute = {
    name: 'SignIn',
    path: '/register',
    exact: true,
    component: Register,
    route: PublicRoute,
}

// 비디오
const videoRoutes = {
    name: 'Videos',
    children: [
        {
            name: 'Videos',
            path: '/videos',
            exact: true,
            component: Videos,
            route: PublicRoute,
        },
        {
            name: 'Videos',
            path: '/videos/:page',
            component: Videos,
            route: PublicRoute,
        }
    ]
};

const customerRoutes = {
    name: 'Customers',
    children: [
        {
            name: 'Inquiry',
            path: '/inquiries',
            exact: true,
            component: Inquiries,
            route: PublicRoute,
        },
    ]
}

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const allRoutes = [
    rootRoute,
    authRoute,
    videoRoutes,
    customerRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, allFlattenRoutes };
