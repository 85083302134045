import React, {Component, Suspense} from 'react';
import {animateScroll as scroll} from "react-scroll";
import VerticalLayout from './VerticalLayout';
const loading = () => <div />;

const withLayout = (WrappedComponent) => {
    return class extends Component {
        render() {
            if (this.props.history.action === 'POP') {
                const pageYOffset = parseInt(localStorage.getItem('pageYOffset'));
                if (!isNaN(pageYOffset) && pageYOffset > 0) {
                    localStorage.setItem('pageYOffset', '0');
                    scroll.scrollTo(pageYOffset, {duration: 500});
                }
            }

            return (
                <Suspense fallback={loading()}>
                    <VerticalLayout {...this.props}>
                        <WrappedComponent {...this.props} />
                    </VerticalLayout>
                </Suspense>
            );
        }
    };
};

export default withLayout;
