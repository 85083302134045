import {Route} from "react-router-dom";
import React from "react";
import {currentUser, getAccessToken, refreshToke} from "../services/AuthService";
import moment from "moment";

const PublicRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            let userId = null
            let user = currentUser();
            if (user) {
                const tokenExpireAt = moment(user.expiredAt);
                const duration = moment.duration(tokenExpireAt.diff(new Date()));
                if (duration.asMinutes() < 1440) {
                    refreshToke(getAccessToken());
                }
                userId = user.userId;
            }

            return <Component {...props} userId={userId} />;
        }}
    />
);

export default PublicRoute;
