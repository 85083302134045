import { combineReducers } from "redux";

export const ACTION_KEYWORD_SELECTED = "keywords/selected";
export const ACTION_GENRE_SELECTED = "genre/selected";
export const ACTION_LABEL_SELECTED = "label/selected";
export const ACTION_MAKER_SELECTED = "maker/selected";
export const ACTION_DIRECTOR_SELECTED = "director/selected";

const videoFilterSelectReducer = (state = { payload: null }, action) => ({
  type: action.type,
  payload: action.payload,
});

export const SelectKeyword = (payload) => ({
  type: ACTION_KEYWORD_SELECTED,
  payload,
});

export const SelectGenre = (genreId) => ({
  type: ACTION_GENRE_SELECTED,
  payload: { value: genreId },
});

export const SelectMaker = (makerId) => ({
  type: ACTION_MAKER_SELECTED,
  payload: { value: makerId },
});

export const SelectLabel = (labelId) => ({
  type: ACTION_LABEL_SELECTED,
  payload: { value: labelId },
});

export const SelectDirector = (directorId) => ({
  type: ACTION_DIRECTOR_SELECTED,
  payload: { value: directorId },
});

export default combineReducers({
  filters: videoFilterSelectReducer,
});
