import React, { Component, Suspense } from 'react';

const Top = React.lazy(() => import('../layout/Top'));
const Footer = React.lazy(() => import('../layout/Footer'));

// loading
const emptyLoading = () => <div />;
const loading = () => <div className="text-center" />;

class VerticalLayout extends Component {
    render() {
        const children = this.props.children || null;
        return (
            <React.Fragment>
                <Suspense fallback={emptyLoading()}>
                    <Top userId={this.props.userId} history={this.props.history} />
                </Suspense>

                <div className="w3-main w3-content w3-padding" style={{maxWidth: '1200px', marginTop: '100px'}}>
                    <Suspense fallback={loading()}>{children}</Suspense>
                </div>

                <Suspense fallback={emptyLoading()}>
                    <Footer />
                </Suspense>
            </React.Fragment>
        );
    }
}

export default VerticalLayout;
