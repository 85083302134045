import HttpCacheAdapter from "./HttpCacheAdapter";

class RequestAuth extends HttpCacheAdapter {
    auth(email, password) {
        return this.authRequest('/access_tokens',
            { email, password }, 'POST');
    }

    refreshToken(access_token) {
        return this.authRequest('/refresh_tokens',
            { access_token }, 'POST');
    }
}

export default RequestAuth;
